import React from "react"
import { StaticQuery, graphql } from "gatsby"

// nodejs library that concatenates classes
import classNames from "classnames"
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
// core components

import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"

import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx"

import SEO from "../../components/SEO.jsx";
import NewsCard from "./Sections/NewsCard.jsx";

const News = ({
  data,
  classes, 
}) => {
  const edges = data.allMarkdownRemark.edges
  const articles = edges.filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    return (
      <div>
        <SEO/>
        <Header
          brand="Planwell"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
        />
        <Parallax image={require("assets/img/turbines.jpg")}>
          <div className={classes.container}>
            <GridContainer />
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
              <GridContainer
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {articles.map((article) => {
              return (
              <NewsCard 
                link={article.node.frontmatter.path}
                title={article.node.frontmatter.title}
                subtitle={article.node.frontmatter.subtitle}
                img={require(`assets/img/${article.node.frontmatter.img}`)}
                date={article.node.frontmatter.date}
                />
              )
              })}
              </GridContainer>
            </div>
        <Footer />
      </div>
    )
}

const NewsWrapped = (rest) => {
  return (
    <StaticQuery
      query={graphql`
      query {
        allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
          edges {
            node {
              id
              frontmatter {
                img
                path
                title
                subtitle
                author
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }`}
      render={data => (
          <News data={data} {...rest}/>
      )}
    />
  )
}


export default withStyles(componentsStyle)(NewsWrapped)
